<template>
  <div class="app-container">
    <div class="page-title">
      <h2>Exportation des données</h2>
    </div>
    <el-card v-if="checkRole(['admin']) || checkRole(['redacteur'])" class="box-card filter-card just-for-admin">
      <!-- <div slot="header" class="clearfix">
        <el-button style="float: right; padding: 3px 0" type="text" @click="initSearch">{{ $t('general.init') }}</el-button>
      </div> -->
      <div class="filter-container">
        <label class="material-label">
          {{ $t('uniteProduction.uniteProduction') }} :
        </label>
        <el-select v-model="uniteProductionId" v-waves class="filter-item" :placeholder="$t('uniteProduction.uniteProduction')" style="width: 180px" prop="unite_production">
          <el-option
            v-for="(up, index) in unite_productions"
            :key="index"
            :label="up.nom | uppercaseFirst"
            :value="up.id"
            :disabled="!up.is_active"
          />
        </el-select>
        <label class="material-label">
          Camp Agricole :
        </label>
        <el-select v-model="campAgricoleId" v-waves class="filter-item" placeholder="Camp Agricole" style="width: 180px" prop="campagne_agricole">
          <el-option
            v-for="(camp, index) in campagnes_agricoles"
            :key="index"
            :label="camp.nom | uppercaseFirst"
            :value="camp.id"
            :disabled="!camp.is_active"
          />
        </el-select>
        <el-button v-if="checkPermission(['insert data'])" v-waves v-loading.fullscreen.lock="fullscreenLoading" :loading="loading2" :disabled="campAgricoleId === null || uniteProductionId === null" class="filter-item" type="primary" icon="el-icon-check" @click="loadData">
          Charger
        </el-button>
      </div>
    </el-card>

    <el-tag v-if="list.length > 0" style="margin-bottom:30px; font-size: 15px">Nombre total de données à charger: {{ list.length }}</el-tag>
    <el-card
      v-if="list.length > 0"
      class="box-card"
    >
      <div class="filter-container">
        <!--  FILTER ELT -->
        <!-- <el-input v-model="query.keyword" :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" />
        <el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search">
          {{ $t('general.search') }}
        </el-button> -->
        <el-button v-if="checkPermission(['export data'])" v-waves v-loading.fullscreen.lock="fullscreenLoading" :loading="downloading" :disabled="campAgricoleId === null || uniteProductionId === null" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload()">
          Export
        </el-button>
        <!--el-button v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload">
          {{ $t('general.export') }}
        </el-button--> </div>
      <!--  TABLE -->
      <el-table
        :data="list.slice((currentPage-1)*PageSize,currentPage*PageSize)"
        border
        fit
        highlight-current-row
        style="width: 100%"
      >
        <el-table-column align="center" label="Code" min-width="100px">
          <template slot-scope="scope">
            <el-link>
              <span>{{ scope.row.code }}</span>
            </el-link>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('producteur.nom')" min-width="200px">
          <template slot-scope="scope">
            <span>{{ scope.row.nom + ' ' + scope.row.prenom }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('producteur.numero')" min-width="150px">
          <template slot-scope="scope">
            <span>{{ scope.row.numero }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('producteur.groupement')" min-width="150px">
          <template slot-scope="scope">
            <span>{{ scope.row.groupement }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="$t('producteur.parcelles')" min-width="150px">
          <template slot-scope="scope">
            <span>{{ parcelles_length(scope.row.historiques) }}</span>
          </template>
        </el-table-column>

      </el-table>

      <!-- pagination v-if="total > 0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getLoadProducteurList" /-->

      <!-- ======= REGION USER DIALOG ======= -->
      <!-- ======= REGION USER DIALOG ======= -->
      <el-pagination
        background
        :current-page="currentPage"
        :page-size="PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="list.length"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
  </div>
</template>

<script>
// import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
// import { parseTime } from '@/utils';
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role';
import Vue from 'vue';
import JsonExcel from 'vue-json-excel';

Vue.component('downloadExcel', JsonExcel);
// import * as XLSX from 'tui-editor'; // Vérification Roles
const uniteProdResource = new Resource('unite_productions');
const campResource = new Resource('campagne_agricoles');
const XLSX = require('xlsx');
export default {
  name: 'Exportation',
  directives: { waves, permission, role },
  data() {
    return {
      tableData: [],
      tableHeader: [],
      output_data: {},
      drawer: false,
      producteurs: {},
      parcelles: [],
      unite_productions: null,
      campagnes_agricoles: null,
      historiques: {},
      currentPage: 1,
      totalCount: 1,
      PageSize: 20,
      loading2: false,
      list: null,
      fullscreenLoading: false,
      loading: true,
      downloading: false,
      disable: true,
      campAgricoleId: null,
      uniteProductionId: null,
      excelEmptyChar: '',
      query: {
        page: 1,
        limit: 100,
        keyword: '',
      },
      data_up: [],
      // clacule des Totaux
      gTotalSuperficie1: 0,
      gTotalSuperficie2: 0,
      gTotalSuperficie3: 0,
      gTotalSuperficieAB: 0,
      gTotalSuperficieSoja: 0,
      gTotalSuperficieSojaEstim: 0,
      gTotalSuperficieSesame: 0,
      gTotalSuperficieArachideEstim: 0,
      gTotalPalmier: 0,
      gTotalKarite: 0,
      gTotalAnacardiers: 0,
      gTotalNeem: 0,
      gTotalNeemEstim: 0,
      gTotalAutres: 0,
      gTotalArbre: 0,
      produitCollectes: [],
      periodesCollecte: [],
    };
  },
  computed: {
  },
  created() {
    this.getLoadProducteurList();
    this.getUniteProdList();
    this.getCampList();
  },
  methods: {
    parcelles_length(parcelles){
      var nb = 0;
      parcelles.forEach(element => {
        if (element.parcelle3 && element.parcelle3[0]) {
          nb++;
        }
        if (element.parcelle2 && element.parcelle2[0]) {
          nb++;
        }
        if (element.parcelle1 && element.parcelle1[0]) {
          nb++;
        }
      });
      return nb;
    },
    setPage(val) {
      this.page = val;
    },
    downloadAsExcel(data) {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = {
        Sheets: {
          'data': worksheet,
        },
        SheetNames: ['data'],
      };
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      console.log(excelBuffer);
      this.saveAsExcel(excelBuffer);
    },
    saveAsExcel(buffer) {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], { type: EXCEL_TYPE });
      this.saveAs(data, 'Export_' + new Date().getTime() + EXCEL_EXTENSION);
    },
    generateAsExcel(list) {
      const data = { 'hum_in': list };
      try {
        const workbook = XLSX.utils.book_new();

        for (const key in data) {
          const worksheet = XLSX.utils.json_to_sheet(data[key]);
          XLSX.utils.book_append_sheet(workbook, worksheet, key);
        }

        const res = XLSX.write(workbook, { type: 'array' });
        console.log(`${res.byteLength} bytes generated`);
      } catch (err) {
        console.log('Error:', err);
      }
    },
    handleDownload() {
      // this.downloadAsExcel(this.list);
      // this.downloading = true;
      /* const main_header = Object.keys(this.list[0]);
      const parcelle_header = ['Parc1', 'Parc2', 'Parc3'];
      const historique_header = this.list[0].historiques[0].parcelle1[0] ? Object.keys(this.list[0].historiques[0].parcelle1[0]) : {};
      */
      // on recupere l'année n à partir de la campagne agricole
      const cp = this.campagnes_agricoles.find(cp => cp.id === this.campAgricoleId);
      console.log('cp annee', this.campagnes_agricoles);
      console.log('cp annee77', cp.periode);
      var currentYear = cp.periode.split('-')[0];
      // var currentYear = this.anneesHistorique(0);
      console.log('annee88', currentYear);
      import('@/vendor/Export2Excel').then(excel => {
        this.formatJson();
        console.log('DATA ', this.data_up);
        this.gTotalSuperficie1 = Math.round(this.gTotalSuperficie1 * 100) / 100;
        this.gTotalSuperficie2 = Math.round(this.gTotalSuperficie2 * 100) / 100;
        this.gTotalSuperficie3 = Math.round(this.gTotalSuperficie3 * 100) / 100;
        this.gTotalSuperficieAB = Math.round(this.gTotalSuperficieAB * 100) / 100;
        this.gTotalSuperficieSoja = Math.round(this.gTotalSuperficieSoja * 100) / 100;
        this.gTotalSuperficieSojaEstim = Math.round(this.gTotalSuperficieSojaEstim * 100) / 100;
        this.gTotalSuperficieSesame = Math.round(this.gTotalSuperficieSesame * 100) / 100;
        this.gTotalSuperficieArachideEstim = Math.round(this.gTotalSuperficieArachideEstim * 100) / 100;
        this.gTotalPalmier = Math.round(this.gTotalPalmier * 100) / 100;
        this.gTotalKarite = Math.round(this.gTotalKarite * 100) / 100;
        this.gTotalAnacardiers = Math.round(this.gTotalAnacardiers * 100) / 100;
        this.gTotalNeem = Math.round(this.gTotalNeem * 100) / 100;
        this.gTotalNeemEstim = Math.round(this.gTotalNeemEstim * 100) / 100;
        this.gTotalAutres = Math.round(this.gTotalAutres * 100) / 100;
        this.gTotalArbre = Math.round(this.gTotalArbre * 100) / 100;
        // Collectes
        var strPeriodesCollecte = [];
        var espaceALaisser = [];
        this.periodesCollecte.forEach(element => {
          espaceALaisser.push('');
          strPeriodesCollecte.push(element.periode);
        });
        var espaceALaisser2 = [...espaceALaisser];
        espaceALaisser2.pop();
        console.log('espaceALaisser2', espaceALaisser2);
        console.log('espaceALaisser', espaceALaisser);
        console.log('strPeriodesCollecte', strPeriodesCollecte);
        var strProduitCollectes = [];
        this.produitCollectes.forEach(element => {
          strProduitCollectes.push(element.produit.nom);
          strProduitCollectes.push(...espaceALaisser2);
        });
        console.log('strProduitCollectes', strProduitCollectes);
        // Declare 4 header levels
        var headerTtaux = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''/* end parc */, this.gTotalSuperficie1, this.gTotalSuperficie2, this.gTotalSuperficie3/* end sup */, '', '', ''/* end gps */, '', '', ''/* end dates */, '', '', ''/* end status */, '', '', ''/* end type */, this.gTotalSuperficieAB/* end total*/, this.gTotalSuperficieSoja, this.gTotalSuperficieSojaEstim/* end total soja*/, this.gTotalSuperficieSesame, ''/* end sesame*/, '', this.gTotalSuperficieArachideEstim/* end arachide*/, ''/* end audit*/, '', '', ''/* end conformites*/, this.gTotalPalmier, '', this.gTotalKarite, '', this.gTotalAnacardiers, '', this.gTotalNeem, this.gTotalNeemEstim, this.gTotalAutres, this.gTotalArbre/* end plan*/];
        var tHeader = ['No', 'NOMS & PRENOMS', 'Codes Producteurs', 'SEXE (M/F)', 'RÉGIONS', 'ZONES', 'COMMUNES', 'VILLAGES', 'COOP / GRPT', 'Adresses producteurs', 'DATE ENTREE DANS LE PROJET', 'HISTORIQUES DES PARCELLES', '', '', '', '', '', '', '', '', '', '', ''/* end parc */, 'SUPERFICIES PARCELLES en Ha', '', ''/* end sup */, 'GPS', '', ''/* end gps */, 'Date de dernière application de substance chimique de synthèse', '', ''/* end dates */, 'STATUTS PARCELLES (AB /C1 / C2 / C3)', '', ''/* end status */, 'TYPE DE CULTURES ( si associe preciser la culture associee sinon mettre P si pure)', '', ''/* end type */, 'SUPERFICIE TOTALE EN AB'/* end total*/, 'SOJA', ''/* end total soja*/, 'SESAME', ''/* end sesame*/, 'ARACHIDE', ''/* end arachide*/, 'Dates de réalisation du dernier audit'/* end audit*/, 'NON CONFORMITES RELEVEES', '', ''/* end conformites*/, 'PLANTS SUR LES PARCELLES BIO', '', '', '', '', '', '', '', '', ''/* end plan*/, 'RECOLTES (QTES COLLECTEES)'];
        var parcelles = ['', '', '', '', '', '', '', '', '', '', '', 'parc 1', '', '', '', 'parc 2', '', '', '', 'parc 3', '', '', ''/* end parc*/, 'PARC 1', 'PARC 2', 'PARC 3'/* end sup*/, 'PARC 1', 'PARC 2', 'PARC 3'/* end gps*/, 'PARC 1', 'PARC 2', 'PARC 3'/* end dates*/, 'PARC 1', 'PARC 2', 'PARC 3'/* end status*/, 'PARC 1', 'PARC 2', 'PARC 3'/* end type */, ''/* end total*/, 'SURFACE TOTALE PARCELLES EN SOjA', 'Estimation récolte ( rdt : kg/ha )'/* end total soja*/, 'SURFACE TOTALE PARCELLES SESAME', 'Estimation récolte ( rdt : kg/ha )'/* end sesame*/, 'SURFACE TOTALE PARCELLES ARACHIDE', 'Estimation récolte ( rdt : kg/ha )'/* end arachide*/, ''/* end audit*/, 'PARC 1', 'PARC 2', 'PARC 3'/* end conformites*/, 'Palmiers', 'Estimation récolte rdt: kg/ha', ' karites ', 'Estimation récolte rdt: kg/ha', 'Anacardiers', 'Estimation récolte rdt: kg/ha', 'Neem', 'Estimation récolte rdt: kg/ha', 'autres', 'Total plants'/* end plan*/];
        parcelles.push(...strProduitCollectes);
        var par_header = ['', '', '', '', '', '', '', '', '', '', '', 'n-3', 'n-2', 'n-1', currentYear, 'n-3', 'n-2', 'n-1', currentYear, 'n-3', 'n-2', 'n-1', currentYear/* end parc*/, '', '', ''/* end sup*/, '', '', ''/* end gps*/, '', '', ''/* end dates*/, '', '', ''/* end status*/, '', '', ''/* end type */, ''/* end total*/, '', ''/* end total soja*/, '', ''/* end sesame*/, '', ''/* end arachide*/, ''/* end audit*/, '', '', ''/* end conformites*/, '', '', '', '', '', '', '', '', '', ''/* end plan*/];
        this.produitCollectes.forEach((element) => {
          par_header.push(...strPeriodesCollecte);
          headerTtaux.push(...espaceALaisser);
          tHeader.push(...espaceALaisser);
        });
        tHeader.pop();
        console.log('parcelles', parcelles);
        console.log('par_header', par_header);
        console.log('headerTtaux', headerTtaux);
        console.log('tHeader', tHeader);
        // List of excel fields to merge
        const merges = [
          'A2:A4',
          'B2:B4',
          'C2:C4',
          'D2:D4',
          'E2:E4',
          'F2:F4',
          'G2:G4',
          'H2:H4',
          'I2:I4',
          'J2:J4',
          'K2:K4',
          'L2:W2',
          'L3:O3',
          'P3:S3',
          'T3:W3',
          'X2:Z2',
          'X3:X4',
          'Y3:Y4',
          'Z3:Z4',
          'AA2:AC2',
          'AA3:AA4',
          'AB3:AB4',
          'AC3:AC4',
          'AD2:AF2',
          'AD3:AD4',
          'AE3:AE4',
          'AF3:AF4',
          'AG2:AI2',
          'AG3:AG4',
          'AH3:AH4',
          'AI3:AI4',
          'AJ2:AL2',
          'AJ3:AJ4',
          'AK3:AK4',
          'AL3:AL4',
          'AM2:AM4',
          'AN2:AO2',
          //
          'AP2:AQ2',
          'AR2:AS2',
          'AN3:AN4',
          'AO3:AO4',
          'AP3:AP4',
          'AQ3:AQ4',
          'AR3:AR4',
          'AS3:AS4',
          'AT2:AT4',
          'AU2:AW2',
          'AU3:AU4',
          'AV3:AV4',
          'AW3:AW4',
          'AX2:BG2',
          'BF3:BF4',
          'BG3:BG4',
          'AX3:AX4',
          'AY3:AY4',
          'AZ3:AZ4',
          'BA3:BA4',
          'BB3:BB4',
          'BC3:BC4',
          'BD3:BD4',
          'BE3:BE4',
          /* 'BH2:BM2',
          'BH3:BI3',
          'BJ3:BK3',
          'BL3:BM3', */
        ];
        this.downloading = false;
        excel.export_json_to_excel({
          multiHeader: [headerTtaux, tHeader, parcelles, par_header],
          header: [],
          data: this.data_up,
          filename: 'Exportation_producteur',
          merges: merges,
        });
      });
    },
    formatJson() {
      // Prepare data to save in exel file
      this.data_up = [];
      console.log('all_data', this.list);
      var count = 1;
      this.list.forEach((data) => {
        const values_data = [];
        // Info Producteur
        values_data.push(count);
        values_data.push(`${data['nom']} ${data['prenom']}`);
        values_data.push(data['code']);
        values_data.push(data['sexe']);
        values_data.push(data['region']);
        values_data.push(data['zone']);
        values_data.push(data['commune']);
        values_data.push(data['village']);
        values_data.push(data['groupement']);
        values_data.push(data['numero'] ? data['numero'] : '');
        values_data.push(data['entr_proj']);

        // Info parcelles
        var empty_list = [this.excelEmptyChar, this.excelEmptyChar, this.excelEmptyChar, this.excelEmptyChar];
        var parc_historiques = data.historiques;
        var parc1 = parc_historiques[0]['parcelle1'].length > 0 ? this.pacellesInfos(parc_historiques[0]['parcelle1']) : empty_list;
        var parc2 = parc_historiques[1]['parcelle2'].length > 0 ? this.pacellesInfos(parc_historiques[1]['parcelle2']) : empty_list;
        var parc3 = parc_historiques[2]['parcelle3'].length > 0 ? this.pacellesInfos(parc_historiques[2]['parcelle3']) : empty_list;
        values_data.push(...parc1);
        values_data.push(...parc2);
        values_data.push(...parc3);

        // Info Superficie parcelles
        var info_superficies = data.superficie;
        var sup1 = typeof info_superficies[0] === 'object' ? info_superficies[0]['superficie'] : this.excelEmptyChar;
        var sup2 = typeof info_superficies[1] === 'object' ? info_superficies[1]['superficie'] : this.excelEmptyChar;
        var sup3 = typeof info_superficies[2] === 'object' ? info_superficies[2]['superficie'] : this.excelEmptyChar;
        values_data.push(sup1);
        values_data.push(sup2);
        values_data.push(sup3);
        sup1 = typeof (sup1) === 'number' ? sup1 : 0;
        sup2 = typeof (sup2) === 'number' ? sup2 : 0;
        sup3 = typeof (sup3) === 'number' ? sup3 : 0;
        this.gTotalSuperficie1 += sup1;
        this.gTotalSuperficie2 += sup2;
        this.gTotalSuperficie3 += sup3;

        // Info GPS parcelles
        var info_gps = data.coordonnees;
        var gps1 = typeof info_gps[0] === 'object' ? this.coordInfos(info_gps[0]['coordonnees']) : this.excelEmptyChar;
        var gps2 = typeof info_gps[1] === 'object' ? this.coordInfos(info_gps[1]['coordonnees']) : this.excelEmptyChar;
        var gps3 = typeof info_gps[2] === 'object' ? this.coordInfos(info_gps[2]['coordonnees']) : this.excelEmptyChar;
        values_data.push(gps1);
        values_data.push(gps2);
        values_data.push(gps3);

        // Info date du dernier usage parcelles
        var datesLastUsage = data.date_dernier_usage;
        parc1 = typeof datesLastUsage[0] === 'object' ? datesLastUsage[0]['date_dernier_usage'] : this.excelEmptyChar;
        parc2 = typeof datesLastUsage[1] === 'object' ? datesLastUsage[1]['date_dernier_usage'] : this.excelEmptyChar;
        parc3 = typeof datesLastUsage[2] === 'object' ? datesLastUsage[2]['date_dernier_usage'] : this.excelEmptyChar;
        values_data.push(parc1 || this.excelEmptyChar);
        values_data.push(parc2 || this.excelEmptyChar);
        values_data.push(parc3 || this.excelEmptyChar);

        // Info status parcelles
        var status = data.status;
        parc1 = typeof status[0] === 'object' ? status[0]['status'] : this.excelEmptyChar;
        parc2 = typeof status[1] === 'object' ? status[1]['status'] : this.excelEmptyChar;
        parc3 = typeof status[2] === 'object' ? status[2]['status'] : this.excelEmptyChar;
        values_data.push(parc1 || this.excelEmptyChar);
        values_data.push(parc2 || this.excelEmptyChar);
        values_data.push(parc3 || this.excelEmptyChar);

        // Info type cultures
        var typeCultures = data.type_culture;
        parc1 = typeof typeCultures[0] === 'object' ? typeCultures[0]['type_culture'] : '';
        parc2 = typeof typeCultures[1] === 'object' ? typeCultures[1]['type_culture'] : '';
        parc3 = typeof typeCultures[2] === 'object' ? typeCultures[2]['type_culture'] : '';
        values_data.push(parc1 || '');
        values_data.push(parc2 || '');
        values_data.push(parc3 || '');

        // Info total superficie
        values_data.push(data['total_superficie_AB']);
        this.gTotalSuperficieAB += typeof (data['total_superficie_AB']) === 'number' ? data['total_superficie_AB'] : 0;

        // Info total superficie soja
        values_data.push(data['total_superficie_soja']);
        values_data.push(''); // valeur Estimation récolte
        this.gTotalSuperficieSoja += typeof (data['total_superficie_soja']) === 'number' ? data['total_superficie_soja'] : 0;
        // this.gTotalSuperficieSojaEstim += 0;

        // Info sesame
        values_data.push(data['total_superficie_sesame']);
        values_data.push(''); // valeur Estimation récolte
        this.gTotalSuperficieSesame += typeof (data['total_superficie_sesame']) === 'number' ? data['total_superficie_sesame'] : 0;
        // Info arachide
        values_data.push(data['total_superficie_arachide']);
        values_data.push(''); // valeur Estimation récolte
        // this.gTotalSuperficieArachideEstim += 0;

        // Info dernier audit
        values_data.push(data['date_dernier_audit'] || this.excelEmptyChar);

        // Info Non conformités
        values_data.push(data['mesures_non_conformites']['parcelle1']);
        values_data.push(data['mesures_non_conformites']['parcelle2']);
        values_data.push(data['mesures_non_conformites']['parcelle3']);

        // Info nombre d'arbre
        var nbr_arb = data.nbr_arb;
        values_data.push(nbr_arb.palmier, '');
        values_data.push(nbr_arb.karite, '');
        values_data.push(nbr_arb.anacardiers, '');
        values_data.push(nbr_arb.neem, '');
        values_data.push(nbr_arb.autres);
        values_data.push(nbr_arb.total);
        this.gTotalPalmier += typeof (nbr_arb.palmier) === 'number' ? nbr_arb.palmier : 0;
        this.gTotalKarite += typeof (nbr_arb.karite) === 'number' ? nbr_arb.karite : 0;
        this.gTotalAnacardiers += typeof (nbr_arb.anacardiers) === 'number' ? nbr_arb.anacardiers : 0;
        this.gTotalNeem += typeof (nbr_arb.neem) === 'number' ? nbr_arb.neem : 0;
        // this.gTotalNeemEstim += 0;
        this.gTotalAutres += typeof (nbr_arb.autres) === 'number' ? nbr_arb.autres : 0;
        this.gTotalArbre += typeof (nbr_arb.total) === 'number' ? nbr_arb.total : 0;

        // RECOLTES QTES COLLECTEES
        console.log('qtes_collectees', Object.keys(this.produitCollectes));
        // Parcourir chaque produit du producteur
        var quantitesParPeriode = [];
        this.produitCollectes.forEach(element => {
          var periodes = data.qtes_collectees[element.produit.nom];
          console.log('periodes', periodes);
          console.log('produit', element.produit.nom);
          if (periodes) {
            console.log('periodes different');
            this.periodesCollecte.forEach(pc => {
              quantitesParPeriode.push(periodes[pc.periode]);
            });
          } else {
            console.log('periodes null__-');
            this.periodesCollecte.forEach(pc => {
              quantitesParPeriode.push('');
            });
          }
          console.log('quantitesParPeriode', quantitesParPeriode);
        });
        values_data.push(...quantitesParPeriode);

        // add a row to the data set to export to excel
        this.data_up.push(values_data);
        count++;
      });
    },
    pacellesInfos(parcelle) {
      // Take a parcel and return this information in the form of a list
      var values_data = [];
      parcelle.forEach((hist) => {
        var val = hist !== null ? (hist['culture_pratique'] ? hist['culture_pratique'] : this.excelEmptyChar) : this.excelEmptyChar;
        values_data.push(val);
      });
      // add an empty field to complete
      for (let i = values_data.length; i < 4; i++) {
        values_data.push(this.excelEmptyChar);
      }
      return values_data;
    },
    coordInfos(gps) {
      // Take a parcel and return this information in the form of a list
      var values_data = '';
      if (gps.length <= 0){
        return this.excelEmptyChar;
      }
      gps.forEach(function(val, idx, gps){
        if (idx === gps.length - 1){
          values_data += `${val.longitude},${val.latitude}`;
        } else {
          values_data += `${val.longitude},${val.latitude};`;
        }
      });
      return values_data;
    },
    anneesHistorique(index) {
      var parc_historiques = this.list[index].historiques;
      console.log('parc_historiques' + index, parc_historiques);
      if (parc_historiques[0]['parcelle1'].length > 0){
        return this.pacellesAnnees(parc_historiques[0]['parcelle1']);
      }
      index += index;
      return this.anneesHistorique(index);
    },
    pacellesAnnees(parcelle) {
      // Take a parcel and return this information in the form of a list
      var hist = parcelle[3];
      var val = hist !== null ? (hist['annee'] ? hist['annee'] : this.excelEmptyChar) : this.excelEmptyChar;
      return val;
    },
    loadData() {
      this.loading2 = true;
      this.list = [];
      const data = { 'campagne_agricole_id': this.campAgricoleId };
      uniteProdResource.sublist('/' + this.uniteProductionId + '/exporter', data)
        .then((response) => {
          console.log('origin data', response);
          this.loading2 = false;
          this.list = response.producteurs;
          this.periodesCollecte = response.periodes_collecte;
          this.produitCollectes = response.produits_collecte;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          console.log('DONE');
        });
    },
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    initSearch(){
    },
    async getUniteProdList() {
      const { data } = await uniteProdResource.list();
      console.log('UNITE PRODUCTION', data);
      this.unite_productions = data;
    },
    async getCampList() {
      const { data } = await campResource.list();
      console.log('CAMP AGRII', data);
      this.campagnes_agricoles = data;
    },
    getLoadProducteurList() {
      this.list = this.tableData;
      console.log('list loaded', this.list);
      this.total = this.list.length;
      this.loading = false;
    },
    // formatJson(filterVal, jsonData) {
    //   return jsonData.map(v => filterVal.map(j => v[j]));
    // },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    onChange(file, fileList){
      var a = this.fileList;
      a.push({ name: file.name, url: file.url });
      // console.log('onChange begin');
      this.fileList = a;
      // console.log(this.fileList);
      // console.log('onChange end');
    },
    handleSizeChange(val) {
      this.PageSize = val;
      // Note: when changing the number of items displayed on each page, the page number should be displayed on the first page
      this.currentPage = 1;
    },
    // What page is displayed
    handleCurrentChange(val) {
      // Change the default number of pages
      this.currentPage = val;
    },
    openFullScreen() {
      this.fullscreenLoading = true;
    },

  },
};
</script>
<style lang="scss" scoped>
.ligne {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.bouton {
  height: 40px;
  width: 40px;
  margin-right: auto;
  margin-left: 15px;
  margin-top: 10px;
}
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
