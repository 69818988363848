<template>
  <div class="app-container">
    <div class="page-title">
      <h2>{{ $t('insertion.insert') }}</h2>
    </div>
    <el-card v-if="checkRole(['admin']) || checkRole(['redacteur'])" class="box-card filter-card just-for-admin">
      <div slot="header" class="clearfix">
        <span>{{ $t('general.insert') }}</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="initSearch">{{ $t('general.init') }}</el-button>
      </div>
      <div class="filter-container">
        <el-button
          v-if="checkPermission(['insert data'])"
          class="mr-15 filter-item"
          type="primary"
          icon="el-icon-upload"
          @click="drawer=true"
        >
          {{ $t('general.load') }}
        </el-button>
        <el-select v-model="uniteProductionId" v-waves class="filter-item" :placeholder="$t('uniteProduction.uniteProduction')" style="width: 180px" prop="unite_production">
          <el-option
            v-for="(up, index) in unite_productions"
            :key="index"
            :label="up.nom | uppercaseFirst"
            :value="up.id"
            :disabled="!up.is_active"
          />
        </el-select>
        <el-select v-model="controleurId" v-waves class="filter-item" :placeholder="$t('controleur.controleur')" style="width: 180px" prop="unite_production">
          <el-option
            v-for="(cont, index) in controleurs"
            :key="index"
            :label="cont.nom | uppercaseFirst"
            :value="cont.id"
            :disabled="!cont.is_active"
          />
        </el-select>
        <el-button v-if="checkPermission(['insert data'])" v-loading.fullscreen.lock="fullscreenLoading" v-waves :loading="loading2" :disabled="disable" class="filter-item" type="primary" icon="el-icon-check" @click="bulkInsert">
          {{ $t('general.send') }}
        </el-button>

      </div>
    </el-card>

    <el-tag v-if="list.length > 0" style="margin-bottom:30px; font-size: 15px">Nombre total de données à charger: {{ list.length }}</el-tag>
    <el-card
      v-if="list.length > 0"
      class="box-card"
    >
      <div class="filter-container">
        <!--  FILTER ELT -->
        <el-input v-model="query.keyword" :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" />
        <el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search">
          {{ $t('general.search') }}
        </el-button>
        <!--el-button v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload">
          {{ $t('general.export') }}
        </el-button--> </div>
      <!--  TABLE -->
      <el-table
        :data="list.slice((currentPage-1)*PageSize,currentPage*PageSize)"
        border
        fit
        highlight-current-row
        style="width: 100%"
      >
        <el-table-column align="center" label="Code" min-width="100px">
          <template slot-scope="scope">
            <el-link>
              <span>{{ scope.row.code }}</span>
            </el-link>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('producteur.nom')" min-width="200px">
          <template slot-scope="scope">
            <span>{{ scope.row.nom + ' ' + scope.row.prenom }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('producteur.numero')" min-width="150px">
          <template slot-scope="scope">
            <span>{{ scope.row.numero }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('producteur.groupement')" min-width="150px">
          <template slot-scope="scope">
            <span>{{ scope.row.groupement }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="$t('producteur.parcelles')" min-width="150px">
          <template slot-scope="scope">
            <span>{{ scope.row.parcelles.length }}</span>
          </template>
        </el-table-column>

      </el-table>

      <!-- pagination v-if="total > 0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getLoadProducteurList" /-->

      <!-- ======= REGION USER DIALOG ======= -->
      <!-- ======= REGION USER DIALOG ======= -->
      <el-pagination
        background
        :current-page="currentPage"
        :page-size="PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <el-dialog
      title="Insertion"
      :visible.sync="drawer"
      width="60%"
    >
      <upload-excel-component :on-success="handleSuccess" />
      <el-button type="success" :disabled="!tableData.length>0" @click="loadData">{{ $t('general.insert') }}</el-button>
    </el-dialog>
  </div>
</template>

<script>
// import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import UploadExcelComponent from '@/components/UploadExcel/index.vue';

const bulkProducteursImportResource = new Resource('producteurs_import');
const uniteProdResource = new Resource('unite_productions');
const controleurResource = new Resource('controleurs');
export default {
  name: 'FormationsList',
  components: { UploadExcelComponent },
  directives: { waves, permission, role },
  data() {
    return {
      tableData: [],
      tableHeader: [],
      output_data: {},
      drawer: false,
      producteurs: {},
      parcelles: [],
      unite_productions: null,
      controleurs: null,
      historiques: {},
      currentPage: 1,
      totalCount: 1,
      PageSize: 20,
      loading2: false,
      list: null,
      fullscreenLoading: false,
      loading: true,
      downloading: false,
      disable: true,
      controleurId: null,
      uniteProductionId: null,
      query: {
        page: 1,
        limit: 100,
        keyword: '',
      },
    };
  },
  computed: {
  },
  created() {
    this.getLoadProducteurList();
    this.getUniteProdList();
    this.getControleurList();
  },
  methods: {
    setPage(val) {
      this.page = val;
    },
    beforeUpload(file) {
      this.$message({
        message: 'Please do not upload files larger than 1m in size.',
        type: 'warning',
      });
      return false;
    },
    handleSuccess({ header, results }) {
      this.tableData = results;
      this.tableHeader = header;
    },
    loadData() {
      const data = { 'producteurs': this.tableData };
      console.log('origin data', data);
      const tbl_length = this.tableData.length;
      let count_row = 1;

      this.tableData = [];

      try {
        for (var p = 2; p <= data['producteurs'].length - 1; p++) {
          this.producteurs = {};
          const nom_prenom = data['producteurs'][p]['NOMS & PRENOMS'].trim();
          this.producteurs.code = data['producteurs'][p]['Codes producteurs'] ? data['producteurs'][p]['Codes producteurs'] : '';
          if (this.producteurs.code !== ''){
            if (this.producteurs.code === undefined && (data['producteurs'][p + 1] !== undefined || this.tableData[p - 3] !== undefined) && (this.producteurs.code === this.tableData[p - 3]['code'] || this.producteurs.code !== data['producteurs'][p + 1]['Codes producteurs'])){
              const zeros = '000';
              const c_code = zeros.substring(0, 4 - tbl_length.toString().length) + (tbl_length + count_row).toString();
              this.producteurs.code = data['producteurs'][tbl_length - 1]['Codes producteurs'].substr(0, data['producteurs'][tbl_length - 1]['Codes producteurs'].length - 4) + c_code;
              console.log('this producteur code after treatment: ', this.producteurs.code);
              count_row++;
            }

            if (data['producteurs'][p + 1] !== undefined && this.tableData[p - 3] !== undefined){
              if (this.producteurs.code !== this.tableData[p - 3]['code'] && this.producteurs.code !== data['producteurs'][p + 1]['Codes producteurs']){
                console.log('ok');
              } else {
                console.log('ce code est dupliqué', this.producteurs.code);
                const zeros = '000';
                const c_code = zeros.substring(0, 4 - tbl_length.toString().length) + (tbl_length + count_row).toString();
                console.log('C CODE', c_code);
                if (data['producteurs'][tbl_length - 1]['Codes producteurs']){
                  this.producteurs.code = data['producteurs'][tbl_length - 1]['Codes producteurs'].substr(0, data['producteurs'][tbl_length - 1]['Codes producteurs'].length - 4) + c_code;
                  console.log('ON EST LA');
                } else {
                  this.producteurs.code = data['producteurs'][2]['Codes producteurs'].substr(0, data['producteurs'][2]['Codes producteurs'].length - 4) + c_code;
                  console.log('this producteur code after treatment: ', this.producteurs.code);
                }
                count_row++;
              }
            }
          }

          if (nom_prenom.indexOf(' ') === -1 || nom_prenom.substr(nom_prenom.indexOf(' ') + 1) === ''){
            this.producteurs.nom = nom_prenom;
            this.producteurs.prenom = nom_prenom;
          } else {
            this.producteurs.nom = nom_prenom.substr(0, nom_prenom.indexOf(' '));
            this.producteurs.prenom = nom_prenom.substr(nom_prenom.indexOf(' ') + 1);
          }
          var sexe = data['producteurs'][p]['SEXE (M/F)'];
          sexe = sexe.substr(0, 1).toUpperCase();
          this.producteurs.sexe = sexe !== 'M' && sexe !== 'F' ? 'M' : sexe;
          var numero = data['producteurs'][p]['Adresses producteurs'];
          this.producteurs.numero = numero === undefined || numero === '-' ? '' : numero;
          const date_entre_projet = data['producteurs'][p]['DATE ENTREE DANS LE PROJET'];
          this.producteurs.date_entre_projet = date_entre_projet !== undefined && !isNaN(date_entre_projet) ? date_entre_projet.toString() : '';
          this.producteurs.region = data['producteurs'][p]['REGIONS'] === undefined ? this.tableData[p - 3].region : data['producteurs'][p]['REGIONS'];

          let region = this.producteurs.region;
          region = region.substr(0, 3).toLowerCase();

          if ('savane'.includes(region)){
            region = 'SAVANES';
          } else if ('kara'.includes(region)){
            region = 'KARA';
          } else if ('central'.includes(region)){
            region = 'CENTRALE';
          } else if ('plateaux'.includes(region)){
            region = 'PLATEAUX';
          } else if ('maritime'.includes(region)){
            region = 'MARITIME';
          }
          this.producteurs.region = region;
          this.producteurs.zone = data['producteurs'][p]['ZONES'] === undefined ? this.tableData[p - 3].zone : data['producteurs'][p]['ZONES'];
          this.producteurs.commune = data['producteurs'][p]['COMMUNES'] === undefined ? this.tableData[p - 3].commune : data['producteurs'][p]['COMMUNES'];
          this.producteurs.village = data['producteurs'][p]['VILLAGES'] === undefined ? this.tableData[p - 3].village : data['producteurs'][p]['VILLAGES'];
          this.producteurs.groupement = data['producteurs'][p]['COOP / GRPT'] === undefined ? this.tableData[p - 3].groupement : data['producteurs'][p]['COOP / GRPT'];
          const last_year = data['producteurs'][1]['__EMPTY_2'];
          // eslint-disable-next-line no-unused-vars
          var culture_count = 1;
          var produit_count = 2;
          // eslint-disable-next-line no-unused-vars
          var superficie_count = 11;
          var status_count = 17;
          var date_count = 15;
          this.parcelles = [];

          const arbre_list = [];
          const palmier = 'palmier=' + (data['producteurs'][p]['PLANTS SUR LES PARCELLES BIO'] || 0);
          arbre_list.push(palmier);
          const karite = 'karite=' + (data['producteurs'][p]['__EMPTY_26'] || 0);
          arbre_list.push(karite);
          const anacard = 'anacardiers=' + (data['producteurs'][p]['__EMPTY_28'] || 0);
          arbre_list.push(anacard);
          const autres = 'autres=' + (data['producteurs'][p]['__EMPTY_30'] || 0);
          arbre_list.push(autres);

          for (var j = 0; j < 3; j++) {
            let superficie;
            let status;
            let date_dernier_usage;
            if (j === 0) {
              date_dernier_usage = data['producteurs'][p]['Date de derniere application de substance chimique de synthèse'];
              superficie = data['producteurs'][p]['SUPERFICIES PARCELLES en Ha'];
              status = data['producteurs'][p]['STATUTS PARCELLES (AB /C1 / C2 / C3)'];
            } else {
              date_dernier_usage = data['producteurs'][p]['__EMPTY_' + date_count];
              superficie = data['producteurs'][p]['__EMPTY_' + superficie_count];
              status = data['producteurs'][p]['__EMPTY_' + status_count];
              status = status !== '-' && status !== undefined ? status : '';
              superficie_count++;
              status_count++;
              date_count++;
            }
            superficie = superficie !== null && superficie !== undefined && !isNaN(superficie) ? superficie : 0;
            if (typeof superficie === 'string'){
              superficie = superficie.replace(';', '.');
              superficie = parseFloat(superficie);
              if (isNaN(superficie)) {
                superficie = 0;
              }
            }
            // date_dernier_usage = isNaN(date_dernier_usage) && date_dernier_usage === 0 ? date_dernier_usage.toString() : '';
            date_dernier_usage = date_dernier_usage || 0;
            var produit = data['producteurs'][p]['__EMPTY_' + produit_count ];
            produit = produit === '-' ? '' : produit;
            produit_count += 4;
            const nbr_arbr = arbre_list.join(';');
            this.parcelles.push({ superficie, date_dernier_usage, status, produit, nbr_arbr });
            this.historiques = [];
            for (var i = 3; i >= 1; i--) {
              var annee = (parseInt(last_year) - i).toString();
              let culture_pratique;
              if (j === 0) {
                if (i === 3) {
                  culture_pratique = data['producteurs'][p]['HISTORIQUES DES PARCELLES'];
                } else if (i === 2) {
                  culture_pratique = data['producteurs'][p]['__EMPTY'];
                } else {
                  culture_pratique = data['producteurs'][p]['__EMPTY_' + culture_count ];
                  culture_count++;
                }
              } else {
                culture_pratique = data['producteurs'][p]['__EMPTY_' + culture_count];
                culture_count++;
              }
              culture_pratique = culture_pratique !== '-' && culture_pratique !== undefined ? culture_pratique : '';
              if (culture_pratique !== '') {
                this.historiques.push({ annee, culture_pratique });
                this.parcelles[j].historiques = this.historiques;
              }
            }culture_count++;
          }
          this.producteurs.parcelles = this.parcelles;
          this.tableData.push(this.producteurs);
        }
        this.drawer = false;
        this.getLoadProducteurList();
        this.disable = false;
      } catch (e) {
        this.$message({
          message: 'Le fichier importé ne correspond pas. Plus d\'information: ' + e.message,
          type: 'warning',
        });
        return false;
      }
    },
    async bulkInsert(){
      this.openFullScreen();
      if (this.controleurId != null && this.uniteProductionId != null){
        this.loading = true;
        this.loading2 = true;
        this.output_data = { 'producteurs': this.tableData, 'controleur': this.controleurId, 'unite_production': this.uniteProductionId };
        console.log('Final output ', this.output_data);
        // await window.axios.post('http://138.201.126.203/soycainwebsite/sciagribeta/public/api/producteurs_import', this.output_data)
        bulkProducteursImportResource.store(this.output_data)
          .then(async(response) => {
            console.log('RESPONSE GET', response, response.status);
            if (response.success === false){
              this.$message({
                message: response.message,
                type: 'info',
                duration: 20 * 1000,
              });
            } else {
              this.$message({
                message: response.message,
                type: 'success',
                duration: 5 * 1000,
              });
            }
            // this.currentProduitId = response.data.id;
            this.loading2 = false;
            this.loading = false;
            this.fullscreenLoading = false;
            this.disable = !this.disable;
            this.controleurId = null;
            this.uniteProductionId = null;
            this.list = [];
          })
          .catch(error => {
            this.loading2 = false;
            this.loading = false;
            this.fullscreenLoading = false;
            this.controleurId = null;
            this.uniteProductionId = null;
            this.list = [];
            console.log('THE STATUS CODE ERROR', error);
            /* if (error.response.status === 524){
              this.$message({
                message: 'L\' insertion se poursuit, veuillez vous assurer que tous les données du fichier son chargées avant de poursuivre',
                type: 'info',
                duration: 20 * 1000,
              });
            }*/
            this.$message({
              message: 'error loading ' + this.$t('producteur.producteurs'),
              type: 'error',
              duration: 5 * 1000,
            });
          })
          .finally(() => {
            this.tableHeader = [];
            this.tableData = [];
          });
      } else {
        this.$message({
          message: ' Veuillez Renseignez les champs controleurs et unite de production ',
          type: 'warning',
          duration: 20 * 1000,
        });
        this.fullscreenLoading = false;
      }
    },
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    initSearch(){
    },
    async getUniteProdList() {
      const { data } = await uniteProdResource.list();
      this.unite_productions = data;
    },
    async getControleurList() {
      const { data } = await controleurResource.list();
      this.controleurs = data;
    },
    getLoadProducteurList() {
      this.list = this.tableData;
      console.log('list loaded', this.list);
      this.total = this.list.length;
      this.loading = false;
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    onChange(file, fileList){
      var a = this.fileList;
      a.push({ name: file.name, url: file.url });
      // console.log('onChange begin');
      this.fileList = a;
      // console.log(this.fileList);
      // console.log('onChange end');
    },
    handleSizeChange(val) {
      this.PageSize = val;
      // Note: when changing the number of items displayed on each page, the page number should be displayed on the first page
      this.currentPage = 1;
    },
    // What page is displayed
    handleCurrentChange(val) {
      // Change the default number of pages
      this.currentPage = val;
    },
    openFullScreen() {
      this.fullscreenLoading = true;
    },

  },
};
</script>

<style lang="scss" scoped>
.ligne {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.bouton {
  height: 40px;
  width: 40px;
  margin-right: auto;
  margin-left: 15px;
  margin-top: 10px;
}
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
