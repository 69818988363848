<template>
  <div class="app-container">
    <div class="page-title">
      <h2>{{ $t('formation.formations') }}</h2>
    </div>
    <el-row :gutter="40" class="panel-group">
      <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
        <div class="card-panel">
          <div class="card-panel-icon-wrapper icon-people">
            <h1>F</h1>
          </div>
          <div class="card-panel-description">
            <div class="card-panel-text">
              Total Formations
            </div>
            <count-to :start-val="0" :end-val="total" :duration="2600" class="card-panel-num" />
          </div>
        </div>
      </el-col>
    </el-row>
    <el-card v-if="checkRole(['admin'])" class="box-card filter-card just-for-admin">
      <div slot="header" class="clearfix">
        <span>{{ $t('general.filter') }}</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="initSearch">{{ $t('general.init') }}</el-button>
      </div>
      <div class="filter-container">
        <el-radio v-model="activeList" label="desactives" border @change="getSubFormationsList('indexdesactive')">{{ $t('general.F_desactives') }}</el-radio>
        <el-radio v-model="activeList" label="disponibles" border @change="handleFilterFormations">{{ $t('general.disponibles') }}</el-radio>
        <el-radio v-model="activeList" label="corbeille" class="danger-radio" border @change="getSubFormationsList('indexinvisible')">{{ $t('general.corbeille') }}</el-radio>
      </div>
      <hr>
      <div style="display:flex; flex-direction:row">
        <el-select
          v-model="campagneFilter"
          placeholder="Campagne"
          style="margin-right:10px; width:200px"
          @change="filterByCampagne(campagneFilter)">
          <el-option
            v-for="(item, index) in campagnes"
            :key="index"
            :label="item.periode"
            :value="index"
          />
        </el-select>
      </div>
    </el-card>

    <el-card class="box-card">
      <div class="filter-container">
        <el-input v-model="query.keyword" :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" @keyup.enter.native="handleFilterFormations" />
        <el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search" @click="handleFilterFormations">
          {{ $t('general.search') }}
        </el-button>
        <el-button v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload">
          {{ $t('general.export') }}
        </el-button>
        <el-button v-if="checkPermission(['creer formation'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="handlecreateFormation">
          {{ $t('general.add') }}
        </el-button>
      </div>
      <el-table v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%">
        <el-table-column align="center" label="Date formation" min-width="100px">
          <template slot-scope="scope">
            <span>{{ scope.row.date_for }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('formation.themeEntete')" min-width="250px">
          <template slot-scope="scope">
            <span>{{ scope.row.theme }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('controleur.controleur')" min-width="200px">
          <template slot-scope="scope">
            <span>{{ libelleControleur[scope.row.controleur_id] }}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="checkPermission(['activer formation'])" align="center" :label="$t('general.status')" width="100">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_active"
              @change="setFormationStatus(scope.row.id,'active')"
            />
          </template>
        </el-table-column>

        <el-table-column v-if="checkRole(['admin']) && (activeList==='corbeille' || query.keyword)" align="center" :label="$t('general.restaurer')" width="100">
          <template slot-scope="scope">
            <el-switch
              v-if="!scope.row.is_visible"
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_visible"
              @change="setFormationStatus(scope.row.id,'visible')"
            />
          </template>
        </el-table-column>

        <el-table-column v-if="checkPermission(['modifier formation','visible formation','supprimer formation'])" align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
          <template slot-scope="scope">
            <div class="flex flex-wrap gap-2 my-2">
              <el-tag v-if="scope.row.resume == null" type="success" class="mx-1" effect="light" round>EN COURS</el-tag>
              <el-tag v-else type="danger" class="mx-1" effect="light" round>TERMINÉ</el-tag>
            </div>
            <!--            <el-button v-if="scope.row.is_visible && checkPermission(['modifier formation'])" type="primary" size="small" icon="el-icon-edit" style="margin-right:10px;" @click="handleEditFormation(scope.row.id);" />-->
            <el-button v-if="checkPermission(['visible formation','supprimer formation'])" slot="reference" type="danger" size="small" icon="el-icon-delete" style="margin-right:10px;" @click="handleDeleteFormation(scope.row.id, scope.row.is_visible, scope.row.libelle);" />
            <!--            <el-button type="info" size="small" icon="el-icon-circle-check" style="margin-right:10px;" @click="handleTerminerFormation(scope.row.id)" />-->
            <el-button type="info" size="small" icon="el-icon-view" style="margin-right:10px;" @click="goToFormation(scope.row.id)" />
          </template>
        </el-table-column>
      </el-table>

      <pagination v-if="total > query.limit" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getFormationsList" />

      <!-- ======= REGION USER DIALOG ======= -->
      <el-dialog
        :title="editing ? $t('formation.edit') + ' ' + currentFormation.libelle : $t('formation.AddFormation')"
        :visible.sync="dialogCreateFormation"
        :before-close="handleCloseCampagneagricoleCreateDraw"
        :loading="formationCreating"
        width="30%"
      >
        <div class="content-inputs">
          <el-form ref="formationForm" :rules="rules" :model="currentFormation" label-position="left">
            <div class="ligne">
              <el-form-item :label="$t('controleur.controleur')" prop="controleur_id">
                <el-select v-model="currentFormation.controleur_id" class="filter-item" :placeholder="$t('controleur.controleur')" style="width: 100%;" prop="controleur_id">
                  <el-option
                    v-for="(controleur, idex) in controleurs"
                    :key="idex"
                    :label="controleur.nom + '    '+ controleur.prenom | uppercaseFirst"
                    :value="controleur.id"
                    :disabled="!controleur.is_active"
                  />
                </el-select>
              </el-form-item>
              <!--              <el-button class="bouton" type="primary" :icon="dialogCreateControleur ? 'el-icon-minus' : 'el-icon-plus'" circle @click="dialogCreateControleur=!dialogCreateControleur" />-->
            </div>
            <el-form-item :label="$t('formation.theme')" prop="theme">
              <el-input v-model="currentFormation.theme" />
            </el-form-item>
            <el-form-item :label="$t('formation.objectifs')" prop="objectifs">
              <el-input v-model="currentFormation.objectifs" type="textarea" rows="5" />
            </el-form-item>
            <el-form-item :label="$t('formation.lieu')" prop="lieu">
              <el-input v-model="currentFormation.lieu" />
            </el-form-item>
            <el-form-item :label="$t('formation.date_for')" prop="date_for">
              <el-date-picker
                v-model="currentFormation.date_for"
                type="date"
                placeholder="Choississez un jour"
                style="width: 100%;"
              />
            </el-form-item>
            <el-form-item :label="$t('formation.duree_for')" prop="duree_for">
              <el-input-number
                v-model.number="currentFormation.duree_for"
                type="number"
                :min="1"
                :max="60"
                maxlength="3"
                :placeholder="$t('formation.duree_for')"
                style="width: 100%;"
              />
            </el-form-item>
          </el-form>
          <p style="font-weight:bold;"><span style="color:red">*</span> Type de formation (Cibles de la Formation) : </p>
          <el-radio-group v-model="ci" size="medium">
            <el-radio-button label="Controleurs" />
            <el-radio-button label="Producteurs" />
          </el-radio-group>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateFormation=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="formationCreating" @click="editing ? updateFormation() : createFormation()">
              {{ formationCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <el-dialog
        :title="$t('controleur.AddControleur')"
        :visible.sync="dialogCreateControleur"
        :before-close="handleCloseCampagneagricoleCreateDraw"
        :loading="controleurCreating"
        width="30%"
      >
        <div class="content-inputs">
          <el-form ref="controleurForm" :rules="rules" :model="currentControleur" label-position="left">
            <el-form-item :label="$t('controleur.nom')" prop="nom">
              <el-input v-model="currentControleur.nom" />
            </el-form-item>
            <el-form-item :label="$t('controleur.prenom')" prop="prenom">
              <el-input v-model="currentControleur.prenom" />
            </el-form-item>
          </el-form>
          <!-- <el-radio-group v-model="ci" size="medium">
            <el-radio-button :label="'audit'">Audit</el-radio-button>
            <el-radio-button :label="'ci'">Contrôle interne</el-radio-button>
          </el-radio-group> -->
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateControleur=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="controleurCreating" @click="editing ? updateControleur() : createControleur()">
              {{ controleurCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <el-dialog
        title="Terminer la Formation"
        :visible.sync="terminerFormationDialogue"
        width="30%"
        center
      >
        <el-form ref="endForm" :rules="rules" :model="formationTerminerData" label-position="left">
          <el-form-item label="Unite Productions" prop="up_id">
            <el-select
              v-model="prod_name"
              multiple
              filterable
              remote
              reserve-keyword
              placeholder="Please enter a keyword"
              :remote-method="filterProducteurs"
              :loading="loading"
              @change="getProducteurSelection"
            >
              <el-option
                v-for="item in producteurs"
                :key="item.id"
                :label="item.nom_prenom"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="Resumé de la formation" prop="resume">
            <el-input v-model="formationTerminerData.resume" type="textarea" />
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="terminerFormationDialogue = false">Annuler</el-button>
          <el-button :disabled="false" type="primary" @click="terminerFormation">Confirmer</el-button>
        </span>
      </el-dialog>

      <!-- ======= REGION USER DIALOG ======= -->

    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
import CountTo from 'vue-count-to';
const controleurResource = new Resource('controleurs');
const producteurRessource = new Resource('producteurs');
const formationResource = new Resource('formations');
const campagneagricoleResource = new Resource('campagne_agricoles');
export default {
  name: 'FormationsList',
  components: { Pagination, CountTo },
  directives: { waves, permission, role },
  data() {
    return {
      controleurs: [],
      libelleControleur: [],
      ci: '',
      resume: '',
      prod_name: '',
      list: null,
      formationTerminerData: {},
      total: 0,
      loading: true,
      downloading: false,
      producteur_ids: [],
      activeList: 'disponibles',
      formationCreating: false,
      controleurCreating: false,
      currentControleur: {},
      editing: false,
      currentFormation: {},
      campagneFilter: null,
      campagnes: null,
      terminerFormationDialogue: false,
      producteurs: {},
      query: {
        page: 1,
        limit: 100,
        keyword: '',
      },
      queryfiltre: {
        page: 1,
        limit: 100,
        keyword: '',
      },
      dialogCreateFormation: false,
      dialogCreateControleur: false,
      currentFormationId: 0,
      rules: {
        libelle: [{ required: true, message: this.$t('formation.LibelleRequired'), trigger: 'blur' }],
        controleur_id: [{ required: true, message: this.$t('formation.ControleurRequired'), trigger: 'blur' }],
        resume: [{ required: true, message: 'Champ Resumé requis', trigger: 'blur' }],
      },
      fileList: [],
    };
  },
  computed: {

  },
  created() {
    this.resetNewFormation();
    this.getFormationsList();
    this.getControleursList();
    this.getCampagnesList();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    handleCloseCampagneagricoleCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    cancelformationForm() {
      this.$refs.CampagneagricoleCreationDrawer.closeDrawer();
    },
    async setFormationStatus(id, type){
      const data = await formationResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.initSearch();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    async filterByCampagne(value){
      this.query.campagne_agricole_id = this.campagnes[value].id;
      this.getFormationsList();
    },
    initSearch(){
      this.handleFilterFormations();
    },
    navigateFormation(id){
      this.$router.push({ path: '/formations/' + id });
    },
    async getFormationsList() {
      this.loading = true;
      const { data, meta } = await formationResource.list(this.query);
      this.list = data.reverse();
      this.total = meta !== undefined ? meta.total : 0;
      console.log('===== EDI');
      console.log(data);
      console.log('===== EVO');
      this.loading = false;
    },
    async getSubFormationsList(subUrl) {
      this.loading = true;
      const { data } = await formationResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    handleFilterFormations() {
      this.query.page = 1;
      this.getFormationsList();
    },
    handlecreateFormation() {
      this.editing = false;
      this.resetNewFormation();
      this.dialogCreateFormation = true;
      this.$nextTick(() => {
        this.$refs['formationForm'].clearValidate();
      });
    },
    handleEditFormation(id){
      this.editing = true;
      this.resetNewFormation();
      this.currentFormation = this.list.find(abonne => abonne.id === id);
      this.dialogCreateFormation = true;
      this.$nextTick(() => {
        this.$refs['formationForm'].clearValidate();
      });
    },
    handleDeleteFormation(id, visible, libelle) {
      console.log('id : ' + id + 'libelle : ' + libelle);
      this.$confirm(this.$t('formation.deleteWarn') + ' ' + libelle + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        console.log('result : ');
        if (!visible && (checkRole(['admin']) || checkPermission(['supprimer formation']))) {
          console.log('result 2 : ');
          this.$swal({
            title: this.$t('general.SureFormation'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              formationResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('formation.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setFormationStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    createFormation() {
      this.$refs['formationForm'].validate((valid) => {
        if (valid && this.ci.length > 0) {
          this.currentFormation.ci = this.ci !== 'Producteurs';
          this.formationCreating = true;
          console.log('CURRENT FORMATION TO SEND', this.currentFormation);
          formationResource
            .store(this.currentFormation)
            .then(async(response) => {
              this.$message({
                message: this.$t('formation.NewFormation') + ' ' + this.currentFormation.libelle + ' ' + this.$t('formation.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentFormationId = response.data.id;
              this.resetNewFormation();
              this.dialogCreateFormation = false;
              this.handleFilterFormations();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.formationCreating = false;
            });
        } else {
          this.$message({
            message: 'Veuillez compléter vos données !',
            type: 'info',
            duration: 5 * 1000,
          });
          console.log('Erreur !!');
          return false;
        }
      });
    },
    resetNewControleur() {
      this.currentControleur = {
        libelle: '',
        description: '',
      };
    },
    createControleur() {
      this.$refs['controleurForm'].validate((valid) => {
        if (valid && this.ci.length > 0) {
          this.controleurCreating = true;
          this.currentControleur.ci = this.ci !== 'audit';
          this.currentControleur.ci = this.ci !== 'audit';
          controleurResource
            .store(this.currentControleur)
            .then(async(response) => {
              this.$message({
                message: this.$t('controleur.NewControleur') + ' ' + this.currentControleur.nom + ' ' + this.$t('controleur.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              console.log('LA NOULEEE : ', response.data.id);
              this.currentFormation.controleur_id = response.data.id;
              this.resetNewControleur();
              this.dialogCreateControleur = false;
              this.handleFilterControleurs();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.controleurCreating = false;
              this.dialogCreateControleur = false;
              this.getControleursList();
            });
        } else {
          this.$message({
            type: 'info',
            message: this.$t('general.formInvalid'),
          });
          return false;
        }
      });
    },
    updateFormation() {
      this.$refs['formationForm'].validate((valid) => {
        if (valid && this.ci.length > 0) {
          this.currentFormation.ci = this.ci === 'Producteurs';
          this.formationCreating = true;
          formationResource.update(this.currentFormation.id, this.currentFormation).then(response => {
            this.resetNewFormation();
            this.handleFilterFormations();
            this.$message({
              message: this.$t('formation.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateFormation = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.formationCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    async handleTerminerFormation(formation_id){
      this.currentFormationId = formation_id;
      console.log('EN COURS');
      this.terminerFormationDialogue = true;
      console.log('Les producteurs', this.producteurs);
    },
    getProducteurSelection(recuperer){
      console.log('RECUPERER ', recuperer);
      this.formationTerminerData.participants = recuperer;
    },
    async filterProducteurs(name){
      const query2 = {
        page: 1,
        limit: 1000,
        keyword: name,
      };
      const { data } = await producteurRessource.list(query2);
      const info_producteurs = [];
      data.forEach((producteur) => {
        info_producteurs.push({ id: producteur.id, nom_prenom: producteur.nom + ' ' + producteur.prenom });
      });
      this.producteurs = info_producteurs;
      console.log('V MODEL ', this.prod_name);
      console.log('LIST DES PRODUCTEUR RETROUVE', data);
    },
    terminerFormation() {
      this.$refs['endForm'].validate((valid) => {
        if (valid && this.formationTerminerData.resume !== '') {
          console.log('ID DE LA FORMATION', this.currentFormationId);
          console.log('DATA TERMINER FORMATION', this.formationTerminerData);
          const affectRessource = new Resource('formation_affecter_participants');
          affectRessource.extra(this.currentFormationId, this.formationTerminerData).then((response) => {
            this.resetNewFormation();
            this.handleFilterFormations();
            this.$message({
              message: this.$t('formation.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.terminerFormationDialogue = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.formationCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    resetNewFormation() {
      this.currentFormation = {
        nom: '',
        theme: '',
        objectifs: '',
        lieu: '',
        date_for: null,
        duree_for: null,
        resume: '',
        controleur_id: null,
      };
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('general.code'), this.$t('formation.libelle')];
        const filterVal = ['id', 'code', 'libelle'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Liste des formations SCIAGRI_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    onChange(file, fileList){
      var a = this.fileList;
      a.push({ name: file.name, url: file.url });
      // console.log('onChange begin');
      this.fileList = a;
      // console.log(this.fileList);
      // console.log('onChange end');
    },
    async getControleursList() {
      const { data } = await controleurResource.list(this.query);
      this.controleurs = data;
      const _reg = [];
      data.forEach(controleur => {
        _reg[controleur.id] = controleur.nom + ' ' + controleur.prenom;
      });
      this.libelleControleur = _reg;
    },
    async getCampagnesList() {
      const { data } = await campagneagricoleResource.list(this.queryfiltre);
      this.campagnes = data;
    },
    goToFormation(formation_id){
      console.log('code de la formation : ', formation_id);
      this.$router.push({ path: '/formations/' + formation_id });
    },
  },
};
</script>

<style lang="scss" scoped>

.panel-group {
  margin-top: 18px;

  .card-panel-col {
    margin-bottom: 32px;
  }

  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);

    &:hover {
      .card-panel-icon-wrapper {
        color: #fff;
      }

      .icon-people {
        background: #40c9c6;
      }

      .icon-message {
        background: #36a3f7;
      }

      .icon-money {
        background: #f4516c;
      }

      .icon-shopping {
        background: #34bfa3
      }
    }

    .icon-people {
      color: #40c9c6;
    }

    .icon-message {
      color: #36a3f7;
    }

    .icon-money {
      color: #f4516c;
    }

    .icon-shopping {
      color: #34bfa3
    }

    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      float: left;
      font-size: 48px;
    }

    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;

      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}

@media (max-width:550px) {
  .card-panel-description {
    display: none;
  }

  .card-panel-icon-wrapper {
    float: none !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}
.ligne {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.bouton {
  height: 40px;
  width: 40px;
  margin-right: auto;
  margin-left: 15px;
  margin-top: 10px;
}
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
